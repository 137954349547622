import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import LeftLogo from "../assets/logoleft.svg";
import Logo1 from "../assets/logo1.svg";
import Logo2 from "../assets/logo2.svg";
import Menu from "../assets/menu.svg";
import { motion } from "framer-motion";
import Logo3 from "../assets/logo3.svg";
import { useNavigate } from "react-router-dom";
import RegisterModal from "../modals/RegisterModal";
const Navbar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const menuItems = ["Our vision", "Our services", "Who are we","Our goals"];
  const menuItems = [
    {
      title: "Our Vision",
      scroll: "our-vision-container",
    },
    {
      title: "Our Services",
      scroll: "our-services-container",
    },
    {
      title: "Who  We Are",
      scroll: "who-are-we-container",
    },
    {
      title: "Our Goals",
      scroll: "our-goals-container",
    },
  ];
  const [open2, setOpen2] = useState(false);

  const handleClose2 = () => {
    setOpen2(false);
  };
  const [open, setOpen] = useState(false);
  const [, setclassName] = useState(false);
  // console.log(data);
  useEffect(() => {
    if (location.pathname.startsWith("/goals")) {
      setclassName(true);
    }
  }, [location.pathname]);
  return (
    <>
      <RegisterModal open={open2} handleClose={handleClose2} />
      <div
        className={
          location.pathname === "/"
            ? "navbar"
            : location.pathname.startsWith("/goals")
            ? "goals-nav navbar"
            : "navbar nav-removed-space"
        }
      >
        <div className="nav-wrap-logo">
          <img src={LeftLogo} alt="" />
          <div
            onClick={() => {
              navigate("/");
            }}
            className="nav-logo"
          >
            <div className="nav-logo-sub-1">
              <img src={Logo1} alt="" />
            </div>
            <div className="nav-logo-sub">
              <motion.img
                initial={{ x: -40, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.9, ease: "easeInOut" }}
                src={Logo2}
                alt=""
              />
              <motion.img
                initial={{ y: 14, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 1.1 }}
                src={Logo3}
                alt=""
              />
            </div>
          </div>
        </div>
        {location.pathname === "/" ? (
          <div className="nav-links">
            {menuItems.map((item, index) => (
              <motion.p
                key={item}
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.4,
                  delay: index * 0.2,
                  ease: "easeInOut",
                }}
                onClick={() => {
                  props.handleClick(item.scroll);
                }}
              >
                {item.title}
              </motion.p>
            ))}
            <motion.div
              className="nav-btn"
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.4,
                delay: 1,
                ease: "easeInOut",
              }}
            >
              <button
                onClick={() => {
                  setOpen2(true);
                }}
              >
                Get Started
              </button>
            </motion.div>
          </div>
        ) : null}

        <div
          onClick={() => {
            setOpen(!open);
          }}
          className="menu-btn"
        >
          <img src={Menu} alt="" />
        </div>
        {open ? (
          <div className="menu-bar">
            {menuItems.map((item, index) => {
              return (
                <>
                  <div
                    onClick={() => {
                      setOpen(false);
                      props.handleClick(item.scroll);
                    }}
                    className="menu-item"
                  >
                    <p>{item.title}</p>
                  </div>
                </>
              );
            })}
            <div
              onClick={() => {
                setOpen(false);
              }}
              className="close-btn"
            >
              <span>Close</span>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Navbar;
