import Goal4Header from "../assets/goal4bg.png";
import Goal4 from "../assets/goal4.png";
export const responsibleconsumptionandproduction = {
  headerImg: Goal4Header,
  goalImg: Goal4,
  title: "RESPONSIBLE CONSUMPTION & PRODUCTION: WHY IT MATTERS",
  tagline:
    // " <span style=color:#FD9D24 >9</span> in <span style=color:#FD9D24 > 10</span>people living in rural areasworldwide were breathing air that did not meet WHO air quality guidelines.",
    "Electronic waste grew by <span style=color:#BF8B2E > 38% </span> but less than <span style=color:#BF8B2E > 20% </span> is recycled",

  color: "#BF8B2E",
  items: [
    {
      question: "What’s the goal here?",
      answer: "To ensure sustainable consumption and production patterns.",
    },
    {
      question: "Why?",
      answer:
        "Economic growth, social development and climate action are heavily dependent on investments in infrastructure, sustainable industrial development and technological progress. In the face of a rapidly changing global economic landscape and increasing inequalities, sustained growth must include industrialization that first of all, makes opportunities accessible to all people, and second, is supported by innovation and resilient infrastructure.",
    },
    {
      question: "What needs to change?",
      answer:
        "There are many aspects of consumption that with simple changes can have a big impact on society as a whole. For example, the global material footprint – an indicator of the pressure put on the environment to support economic growth and to satisfy the material needs of people – grew by 17.4 per cent to 85.9 billion metric tons in 2017 as compared to 2010. Reducing food loss and waste can contribute to environmental sustainability by lowering production costs and increasing the efficiency of food systems. Currently, we lose 13.8 per cent after harvesting and during transport, storage and processing alone, amounting to a cost of over $400 billion a year. We are also polluting water faster than nature can recycle and purify water in rivers and lakes.",
    },
    {
      question: "How can I help as a business?",
      answer:
        "It’s in businesses’ interest to find new solutions that enable sustainable consumption and production patterns. A better understanding of environmental and social impacts of products and services is needed, both of product life cycles and how these are affected by use within lifestyles. Identifying “hot spots” within the value chain where interventions have the greatest potential to improve the environmental and social impact of the system as a whole is a crucial first step. Innovation and design solutions can both enable and inspire individuals to lead more sustainable lifestyles, reducing impacts and improving well-being",
    },
    {
      question: "How can I help as a consumer?",
      answer:
        "There are two main ways to help: 1. Reducing your waste and 2. Being thoughtful about what you buy and choosing a sustainable option whenever possible. Ensure you don’t throw away food, and reduce your consumption of plastic—one of the main pollutants of the ocean. Carrying a reusable bag, refusing to use plastic straws, and recycling plastic bottles are good ways to do your part every day. Making informed purchases also helps. For example, the textile industry today is the second largest polluter of clean water after agriculture, and many fashion companies exploit textile workers in the developing world. If you can buy from sustainable and local sources you can make a difference as well as exercising pressure on businesses to adopt sustainable practices. To take action, visit: http:// www.un.org/en/actnow To find out more about Goal #12 and other Sustainable Development Goals, visit: http://www.un.org/ sustainabledevelopment",
    },
  ],
};
