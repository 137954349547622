import Goal3Header from "../assets/goal3bg.png";
import Goal3 from "../assets/goal3.png";
export const sustainablecitiesandcommunities = {
  headerImg: Goal3Header,
  goalImg: Goal3,
  title: "SUSTAINABLE CITIES: WHY THEY MATTER",
  tagline:
    " <span style=color:#FD9D24 >9</span> in <span style=color:#FD9D24 > 10</span>people living in rural areasworldwide were breathing air that did not meet WHO air quality guidelines.",

  color: "#FD9D24",
  items: [
    {
      question: "What’s the goal here?",
      answer:
        "To make cities inclusive, safe, resilient and sustainable",
    },
    {
      question: "Why?",
      answer:
        "Over 90 per cent of COVID19 cases are occurring in urban areas, with the 1 billion residents of the world’s densely populated slums being hit the hardest. Even before the coronavirus, rapid urbanization meant that 4 billion people – over half of the global pupulation – in the world’s cities faced worsening air pollution, inadequate infrastructure and services, and unplanned urban sprawl. Successful examples of containing COVID-19 demonstrate the remarkable resilience and adaptability of urban communities in adjusting to new norms." },
    {
      question: "What are some of the most pressing challenges that cities face today?",
      answer:
      "Inequality and the levels of urban energy consumption and pollution are some of the challenges. Cities occupy just 3 per cent of the Earth’s land, but account for 60-80 per cent of energy consumption and 75 per cent of carbon emissions. Many cities are also more vulnerable to climate change and natural disasters due to their high concentration of people and location so building urban resilience is crucial to avoid human, social and economic losses."},
    {
      question: "Why should I care?",
      answer:
        "All these issues will eventually affect every citizen. Inequality can lead to unrest and insecurity, pollution deteriorates everyone’s health and affects workers’ productivity and therefore the economy, and natural disasters have the potential to disrupt everyone’s lifestyles." },
    {
      question: "What happens if cities are just left to grow organically?",
      answer:
       "The cost of poorly planned urbanization can be seen in some of the huge slums, tangled traffic, greenhouse gas emissions and sprawling suburbs all over the world. By choosing to act sustainably we choose to build cities where all citizens live a decent quality of life, and form a part of the city’s productive dynamic, creating shared prosperity and social stability without harming the environment. As of May 2020, the majority of national and city governments are revisiting urban planning to help prevent the next pandemic"},
    {
      question: "Is it expensive to put sustainable practices in place?",
      answer:
      "The cost is minimal in comparison with the benefits. For example, there is a cost to creating a functional public transport network, but the benefits are huge in terms of economic activity, quality of life, the environment, and the overall success of a networked city" },
    {
      question: "What can I do to help achieve this goal?",
      answer:
        "Take an active interest in the governance and management of your city. Advocate for the kind of city you believe you need. Develop a vision for your building, street, and neighbourhood, and act on that vision. Are there enough jobs? Can your children walk to school safely? Can you walk with your family at night? How far is the nearest public transport? What’s the air quality like? What are your shared public spaces like? The better the conditions you create in your community, the greater the effect on quality of life. To find out more about Goal #11 and other Sustainable Development Goals, visit: http://www.un.org/ sustainabledevelopment" },
  ],
};
