import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import WhiteLogo from "../assets/footer-logo.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  borderRadius: 3,
  width: window.innerWidth > 600 ? "550px" : "90%",
  outline: "none",
  p: window.innerWidth > 600 ? 4 : 3,
};
const Service = ({ open, handleClose, data }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="service-modal">
            <div className="service-logo">
              <img src={WhiteLogo} alt="" />
            </div>
            <div className="service-modal-text">
              <h1>{data?.title}</h1>

              <button
                onClick={() => {
                  if (data?.available) {
                    window.open(data?.link, "_blank");
                  }
                }}
              >
                {data?.available ? data?.button : "Coming Soon"}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Service;
