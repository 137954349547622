import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import {   
  scroller,
} from "react-scroll";
const Goal1 = ({ data }) => {
  const navigate = useNavigate(); 
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.startsWith("/goals")) {
      // document.body.style.background = "#F3F2EE";
    }
  }, [location.pathname]);
  const handleClick = (section) => {
    scroller.scrollTo(section, {
      smooth: true,
      offset: -100,
      duration: 1200,
    });
  };
  return (
    <>
      <Navbar handleClick={handleClick} />
      <div className="goals-page-wrap">
      <div className="bread-crumb">
        <p onClick={()=>{
          navigate(-1);
          
        }}>Home 
          &gt;
        </p>
        <p>{data.title}</p>
      </div>
      <div className="goals-page-header-img">
        <img src={data.headerImg} alt="" />
      </div>
      <div className="goals-phone-container">
        <img src={data.goalImg} alt="" />
        <h1
          id="tagline"
          dangerouslySetInnerHTML={{
            __html: data.tagline,
          }}
        ></h1>
      </div>
      <div className="goals-page">
        <div className="goals-page-content">
          <div className="goals-page-content-text">
            <div className="goals-page-header">
              <h1 style={{ color: data.color }}>{data.title}</h1>
            </div>
            <div className="goals-page-questions">
              {data.items.map((question, index) => {
                return (
                  <div key={index} className="goals-page-question">
                    <h1>{question.question}</h1>
                    <p>{question.answer}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="goals-page-tagline">
          <div className="goals-page-tagline-img">
            <img src={data.goalImg} alt="" />
          </div>
          <div className="goals-page-tagline-content">
            <h1
              id="tagline"
              dangerouslySetInnerHTML={{
                __html: data.tagline,
              }}
            ></h1>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Goal1;
