import Goal1Header from "../assets/goal1bg.png";
import Goal1 from "../assets/goal1.png";
export const affordableandcleanenergy = {
  headerImg: Goal1Header,
  goalImg: Goal1,
  title: "AFFORDABLE AND CLEAN ENERGY: WHY IT MATTERS",
  tagline:
    "<span style=color:#FBB618 > 789</span> million people around the world lack access to electricity",
  color: "#FBB618",
  items: [
    {
      question: "What’s the goal here?",
      answer:
        "To build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation",
    },
    {
      question: "Why?",
      answer:
        "A well-established energy system supports all sectors: from businesses, medicine and education to agriculture, infrastructure, communications and high-technology. Access to electricity in poorer countries has begun to accelerate, energy efficiency continues to improve, and renewable energy is making impressive gains. Nevertheless, more focused attention is needed to improve access to clean and safe cooking fuels and technologies for 2.8 billion people.",
    },
    {
      question: "Why should I care about this goal??",
      answer:
        "For many decades, fossil fuels such as coal, oil or gas have been major sources of electricity production, but burning carbon fuels 789 million people around the world lack access to electricity  produces large amounts of greenhouse gases which cause climate change and have harmful impacts on people’s well-being and the environment. This affects everyone, not just a few. Moreover, global electricity use is rising rapidly. In a nutshell, without a stable electricity supply, countries will not be able to power their economies",
    },
    {
      question: "How many people are living without electricity??",
      answer:
        "Nearly 9 out of 10 people now have access to electricity, but reaching the unserved 789 million around the world – 548 million people in subSaharan Africa alone – that lack access will require increased efforts. Without electricity, women and girls have to spend hours fetching water, clinics cannot store vaccines for children, many schoolchildren cannot do homework at night, and people cannot run competitive businesses. Slow progress towards clean cooking solutions is of grave global concern, affecting both human health and the environment, and if we don’t meet our goal by 2030, nearly a third of the world’s population – mostly women and children – will continue to be exposed to harmful household air pollution.",
    },
    {
      question: "What are the consequences to lack of access to energy?",
      answer:
        "Lack of access to energy may hamper efforts to contain COVID-19 across many parts of the world. Energy services are key to preventing disease and fighting pandemics – from powering healthcare facilities and supplying clean water for essential hygiene, to enabling communications and IT services that connect people while maintaining social distancing.",
    },
    {
      question: "What can we do to fix these issues?",
      answer:
        "Countries can accelerate the transition to an affordable, reliable, and sustainable energy system by investing in renewable energy resources, prioritising energy efficient practices, and adopting clean energy technologies and infrastructure. Businesses can maintain and protect ecosystems and commit to sourcing 100% of operational electricity needs from renewable sources.Employers can reduce the internal demand for transport by prioritising telecommunications and incentivise less energy intensive modes such as train travel over auto and air travel. Investors can invest more in sustainable energy services, bringing new technologies to the market quickly from a diverse supplier base. You can save electricity by plugging appliances into a power strip and turning them off completely when not in use, including your computer. You can also bike, walk or take public transport to reduce carbon emissions. To find out more about Goal #7 and other Sustainable Development Goals,  visit: http://www.un.org/sustainabledevelopment",
    },
  ],
};
