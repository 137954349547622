import React from "react";
import Lottie from "lottie-react";
import * as animationData from "../animation/404.json";
const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <Lottie animationData={animationData} loop={true} />
    </div>
  );
};

export default PageNotFound;
