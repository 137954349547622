import React, { useEffect } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { BrowserRouter } from "react-router-dom";
import Navigation from "./routes/routes";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function App() {
  const handleClick = (section) => {
    scroller.scrollTo(section, {
      smooth: true,
      offset: -100,
      duration: 1200,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <BrowserRouter>
      <ToastContainer />
      {/* <Navbar /> */}
      <Navigation />
      {window.location.pathname === "/" ||
      window.location.pathname.startsWith("/goals") ? (
        <Footer handleClick={handleClick} />
      ) : null}
      {/* <Footer  /> */}
      {/* {window.location.pathname === "/" ? null : <Footer />} */}
    </BrowserRouter>
  );
}

export default App;
