import Goal5Header from "../assets/goal5bg.png";
import Goal5 from "../assets/goal5.png";
export const climateaction = {
  headerImg: Goal5Header,
  goalImg: Goal5,
  title: "CLIMATE ACTION: WHY IT MATTERS",
  tagline:
    // " <span style=color:#FD9D24 >9</span> in <span style=color:#FD9D24 > 10</span>people living in rural areasworldwide were breathing air that did not meet WHO air quality guidelines.",
    "To limit global warming to 1.5C, as called for in the Paris Agreement, greenhouse gas emissions must begin falling by <span style=color:#3F7E44 > 7.6%  </span>  each year starting in 2020",
  color: "#3F7E44",
  items: [
    {
      question: "What’s the goal here?",
      answer: "Taking urgent action to tackle climate change and its impacts.",
    },
    {
      question: "Why?",
      answer:
        "The climate crisis continues unabated as the global community shies away from the full commitment required for its reversal. 2010-2019 was warmest decade ever recorded, bringing with it massive wildfires, hurricanes, droughts, floods and other climate disasters across continents.",
    },
    {
      question: "How are people being affected by climate change?",
      answer:
        "Climate change is affecting every country in the world. It is disrupting national economies and affecting lives and livelihoods, especially for the most vulnerable. Weather patterns are changing, sea levels are rising, and weather events are becoming more extreme, affecting more than 39 million people in 2018",
    },
    {
      question: "What happens if we don’t take action?",
      answer:
        "If left unchecked, climate change will cause average global temperatures to increase beyond 3°C, and will adversely affect every ecosystem. Already, we are seeing how climate change can exacerbate storms and disasters, and threats such as food and water scarcity, which can lead to conflict. Doing nothing will end up costing us a lot more than if we take action now. We have an opportunity to take actions that will lead to more jobs, great prosperity, and better lives for all while reducing greenhouse gas emissions and building climate resilience",
    },
    {
      question: "Can we solve this problem or is it too late to act?",
      answer:
        "To address climate change, we have to vastly increase our efforts. Much is happening around the world – investments in renewable energy have soared. But so much more needs to be done. The world must transform its energy, industry, transport, food, agriculture and forestry systems to ensure that we can limit global temperature rise to well below 2°C, maybe even 1.5°C. In December 2015, the world took a significant first step by adopting the Paris Agreement, in which all countries committed to take action to address climate change. Many businesses and investors are also committing themselves to lower their emissions, not just because it is the right thing to do, but because it makes economic and business sense as well.",
    },
    {
      question: "Are we investing enough to tackle climate change?",
      answer:
        "Global climate-related financial flows saw a 17 per cent rise from 2013 to 2016, largely due to private investment in renewable energy, which represents the largest segment in total climate-related flows, to the amount of $681 billion. However, investment in fossil fuels continues to be higher than in climate activities to the amount of $781 billion in 2016. To achieve a low-carbon, climateresilient transition, a much greater scale of annual investment is required. In 2019, at least 120 of the 153 developing countries had undertaken activities to formulate and implement National Adaptation Plans to enhance climate adaptation and resilience, an increase of 29 countries over the previous year. Furthermore, progress in meeting the 2020 disaster risk reduction target has been slow",
    },
    {
      question: "What can I do to help achieve this goal?",
      answer:
        "There are many things that each of us can do as individuals. To find out what you can do, go to: https:// www.un.org/en/actnow To read more about the UN’s efforts on climate change: https://www. un.org/en/climatechange To find out more about Goal #13 and other Sustainable Development Goals, visit: http://www.un.org/ sustainabledevelopment",
    },
  ],
};
