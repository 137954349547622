import React, { useEffect, useState } from "react";
import Typewriter from "typewriter-effect";
import { scroller } from "react-scroll";
import { useLocation } from "react-router-dom";
import Service1 from "../../assets/service1.svg";
import { useNavigate } from "react-router-dom";
import Globe from "../../assets/globe.svg";
import Sustainable from "../../assets/sustainable-img2.png";
import Lottie from "lottie-react";
import * as animationData from "../../animation/globe.json";
import Service2 from "../../assets/service2.svg";
import Service3 from "../../assets/service3.svg";
import Service4 from "../../assets/service4.svg";
import Service5 from "../../assets/service5.svg";
import Service6 from "../../assets/service6.svg";
import Goal1 from "../../assets/goal1.png";
import Goal2 from "../../assets/goal2.png";
import Goal3 from "../../assets/goal3.png";
import Goal4 from "../../assets/goal4.png";
import Goal5 from "../../assets/goal5.png";
import Navbar from "../../components/Navbar";
import RegisterModal from "../../modals/RegisterModal";
import RegisterModal2 from "../../modals/RegisterModal2";
import Service from "../../modals/Service";

const Index = () => {
  const handleClick = (section) => {
    scroller.scrollTo(section, {
      smooth: true,
      offset: -100,
      duration: 1200,
    });
  };
  const navigate = useNavigate();
  const goals = [
    {
      img: Goal1,
      path: "/goals/affordable-and-cleanenergy",
    },
    {
      img: Goal2,
      path: "/goals/industrial-innovation-and-infrastructure",
    },
    {
      img: Goal3,
      path: "/goals/sustainable-cities-and-communities",
    },
    {
      img: Goal4,
      path: "/goals/responsible-consumption-and-production",
    },
    {
      img: Goal5,
      path: "/goals/climate-action",
    },
  ];
  const location = useLocation();
  // console.log(data);
  useEffect(() => {
    if (location.pathname === "/") {
      document.body.style.background = "#fff";
    }
  }, [location.pathname]);

  const data = [
    {
      title: "Sustainability and Circular Economy Consultation",
      available: false,
      img: Service1,
    },
    {
      title: "Sustainable Retro commissioning",
      available: false,
      img: Service2,
    },
    {
      title: "System Integration and Automation",
      available: false,
      img: Service3,
    },
    {
      title: "Efficiency Monitoring Tools",
      available: false,
      img: Service4,
    },
    {
      title: "Repurposing and Recycling Solutions",
      available: true,
      button: "Visit Recall",
      link: "https://recalluae.com",
      img: Service5,
    },
    {
      title: "Reverse Logistics Management",
      available: false,
      img: Service6,
    },
  ];
  const [open, setOpen] = useState(false);
  const [servicedata, setServiceData] = useState();

  const handleClose = () => {
    setOpen(false);
  };
  const [open2, setOpen2] = useState(false);

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [open3, setOpen3] = useState(false);

  const handleClose3 = () => {
    setOpen3(false);
  };
  return (
    <>
      <Service open={open3} handleClose={handleClose3} data={servicedata} />
      <RegisterModal2 open={open} handleClose={handleClose} />
      <RegisterModal open={open2} handleClose={handleClose2} />
      <Navbar handleClick={handleClick} />
      <div className="home-page">
        <div
          itemscope
          itemtype="http://schema.org/Organization"
          className="home-page-first-section"
        >
          <h1>Simplifying Sustainability for All</h1>
          <p>
            Achieving Sustainability through Efficiency and enabling a seamless
            transition towards True Circularity.
          </p>
          <div className="home-page-btns">
            <button
              onClick={() => {
                setOpen2(true);
              }}
              className="btn | primary-btn"
            >
              Get Started
            </button>
            <button
              onClick={() => {
                setOpen(true);
              }}
              className="btn | secondary-btn"
            >
              Learn More
            </button>
          </div>
        </div>

        <div className="home-page-first-section-3">
          <div className="home-page-first-section-3-heading">
            <h1>
              S <span className="sup">2</span> <span>=</span>
            </h1>
            <Typewriter
              options={{
                strings: [
                  "Sustainable ", 
                  "Efficient",
                  "Circular",
                  "Solutions",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </div>
          <p>
            We believe that the best way to accelerate the movement towards
            global sustainability is to engage small and medium sized
            organizations, especially in the developing world, as they form the
            majority of the world’s economy.
          </p>
        </div> 
        <div  className="new-our-vision">
          <div id="our-vision-container"className="new-our-vision-left">
            <div className="new-our-vision-left-header">
              {/* <img src={OurVision} alt="" /> */}
              <h1>Our Vision</h1>
            </div>
            <p>
              "At S<sup>2</sup>Loop, we envision a world where sustainability,
              efficiency, and ethics harmoniously coexist, paving the way for a
              circular global community."
            </p>
            <span>
              {" "}
              Our purpose is to serve as the foremost enabler of sustainable
              practices, catalyzing a transformative shift towards a
              regenerative and interconnected planet.
            </span>
          </div>
          <div className="new-our-vision-right">
            <div className="new-our-vision-right-container">
              {/* {/* <div className="home-page-first-section-5-blob-1"></div> */}
              {/* <div className="home-page-first-section-5-blob-1 home-page-first-section-5-blob-2"></div>  */}
              <p>
                We see a future where businesses, governments, and communities
                operate in seamless synergy with the natural world, respecting
                its delicate balance and replenishing what is consumed. Through
                our unwavering commitment to innovation and ethical principles,
                we aim to redefine industry standards, setting a new benchmark
                for responsible enterprises.
                <br /> <br />
                Our vision is a testament to our dedication in fostering a
                planet where resources are cherished, waste is minimized, and
                every action is guided by the pursuit of long-term ecological
                and societal well-being. By providing bespoke services, we
                empower small and medium organizations to integrate
                sustainability into their DNA, ensuring not only their survival
                but also their prosperity in a rapidly changing world.
                <br /> <br />
                Together, we embark on a journey towards a future where
                sustainability is not a choice, but a collective responsibility.
                With S<sup>2</sup>Loop at the forefront, we are poised to lead
                the charge towards a circular global community, where every
                individual, every business, and every nation contributes to a
                thriving, balanced, and harmonious world for generations to
                come.
              </p>{" "}
            </div>
          </div>
        </div>
        <div id="our-services-container" className="our-services">
          <h1>Our Services</h1>
          <p>
            Subscribe to our services so that you get all the updates coming{" "}
            <br /> on our platform
          </p>
          <div className="our-service-cards">
            {data.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setServiceData(item);
                    setOpen3(true);
                  }}
                  key={index}
                  className="our-service-card"
                >
                  <img src={item.img} alt={item.title} />
                  <h2>{item.title}</h2>
                </div>
              );
            })}
          </div>
        </div>
        <div className="home-page-first-section-5-wrap">
          <div className="home-page-first-section-5">
            {/* <div className="home-page-first-section-5-blob-1"></div>
            <div className="home-page-first-section-5-blob-1 home-page-first-section-5-blob-2"></div> */}

            <div className="home-page-first-section-5-container">
              <div className="home-page-first-section-5-container-text">
                <h1>
                  We Cannot Become Circular <br /> Alone
                </h1>
                <p>
                  Closing the loop towards circularity needs close coordination
                  and cooperation with many entities within a society. S
                  <sup>2</sup> Loop partners with leading organizations that
                  create the most efficient systems in an environmentally
                  responsible manner. Our collaborations enable us to formulate
                  diverse and dynamic solutions tailored to our customers needs.
                  We help connect the dots to carefully build a truly circular
                  economy.
                </p>
                <button
                  onClick={() => {
                    setOpen2(true);
                  }}
                  className="btn | primary-btn"
                >
                  Get Started
                </button>
              </div>
              <div className="home-page-first-section-5-globe">
                <img src={Globe} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div id="who-are-we-container" className="home-page-first-section-6">
          <div className="home-page-first-section-6-lottie">
            <Lottie animationData={animationData} loop={true} />
          </div>
          <div className="home-page-first-section-6-text">
            <h1>Who We Are</h1>
            <p>
              S<sup>2</sup>Loop emerges as a visionary social enterprise, fueled by
              a group of passionate engineers dedicated to optimizing systems
              and practices, driven by a profound concern for our precious
              planet.
              <br /> <br />
              Born from this fervor, S<sup>2</sup> Loop aspires to be the
              driving force that propels small and medium industries towards
              efficient, sustainable operations. Simultaneously, it endeavors to
              empower individuals and communities with the tools and mindset
              needed to embrace a lifestyle rooted in sustainability and
              circularity.
              <br /> <br /> With a meticulous examination of existing
              operational frameworks within any organization, S<sup>2</sup>
              Loop crafts bespoke solutions that are aligned with our clients'
              operational and sustainability objectives, all while respecting
              their financial constraints. We believe that by enabling and
              educating the world to make the leap towards circular practices,
              we can accelerate positive results and hinder the damages done due
              to current activities.
            </p>
          </div>
        </div>
        <div
          id="our-goals-container"
          className="home-page-first-section-7-wrap"
        >
          <div className="home-page-first-section-7">
            <div className="home-page-first-section-7-flex">
              <h1>Our Goals =</h1>
              <img src={Sustainable} alt="" />
            </div>
            <p>
              Our goals align with UN's Sustainable Development Goals. We work
              consciously to ensure all our activities contribute to towards
              these goals.
            </p>
          </div>
        </div>
        <div className="home-page-first-section-8">
          <div className="sustainable-goals">
            {goals.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    navigate(item.path);
                  }}
                  key={index}
                  className="sustainable-goal"
                >
                  <img src={item.img} alt="" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
