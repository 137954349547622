import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import { useLocation } from "react-router-dom";
import Goal1 from "../pages/Goal1";
import { affordableandcleanenergy } from "../data/affordableandcleanenergy";
import { industrialinnovationandinfrastructure } from "../data/industrialinnovationandinfrastructure";
import { sustainablecitiesandcommunities } from "../data/sustainablecitiesandcommunities";
import { responsibleconsumptionandproduction } from "../data/responsibleconsumptionandproduction";
import { climateaction } from "../data/climateaction";
import PageNotFound from "../pages/PageNotFound";
const routes = [
  {
    path: "/",
    exact: true,
    element: <Home />,
    private: true,
  },
  {
    path: "*",
    exact: true,
    element: <PageNotFound />,
    private: true,
  },
  {
    path: "/goals/affordable-and-cleanenergy",
    exact: true,
    element: <Goal1 data={affordableandcleanenergy} />,
    private: true,
  },
  {
    path: "/goals/industrial-innovation-and-infrastructure",
    exact: true,
    element: <Goal1 data={industrialinnovationandinfrastructure} />,
    private: true,
  },
  {
    path: "/goals/sustainable-cities-and-communities",
    exact: true,
    element: <Goal1 data={sustainablecitiesandcommunities} />,
    private: true,
  },
  {
    path: "/goals/responsible-consumption-and-production",
    exact: true,
    element: <Goal1 data={responsibleconsumptionandproduction} />,
    private: true,
  },
  {
    path: "/goals/climate-action",
    exact: true,
    element: <Goal1 data={climateaction} />,
    private: true,
  },
];

export default function Navigation() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}
