import React, { useState } from "react";
import Canada from "../assets/canada.png";
import Home from "../assets/home.svg";
import PhoneIcon from "../assets/phoneicon.svg";
import MailIcon from "../assets/mailimg.svg";
import Care from "../assets/care.svg";
import Insta from "../assets/instagram.svg";
import Facebook from "../assets/facebook.svg";
import Twitter from "../assets/twitter.svg";
import Linkedin from "../assets/linkedin.svg";
import Location from "../assets/location.svg";
import WhiteLogo from "../assets/footer-logo.png";
import RegisterModal from "../modals/RegisterModal";
// import RegisterModal from "../Modals/RegisterModal";
const Footer = (props) => {
  const [open2, setOpen2] = useState(false);

  const handleClose2 = () => {
    setOpen2(false);
  };
  return (
    <footer>
      <RegisterModal open={open2} handleClose={handleClose2} />
      <div className="bottom-footer">
        <div className="bottom-footer-flex">
          <div className="bottom-footer-left">
            <img src={WhiteLogo} alt="" />
            <div className="location">
              <img src={Location} alt="" />
              <p>
                55 Smooth Rose Court <br />
                Toronto ON M2J 0G7 <br />
                <p className="canada">
                  {" "}
                  Canada <img src={Canada} alt="" />
                </p>
              </p>
            </div>

            <div className="">
              <button
                className="btn | landing-btn"
                onClick={() => {
                  setOpen2(true);
                }}
              >
                Get Started
              </button>
            </div>
          </div>
          <div className="bottom-footer-right">
            <div className="bottom-footer-right-one">
              <h1>Quick Links</h1>
              <p
                onClick={() => {
                  props.handleClick("our-vision-container");
                }}
              >
                {" "}
                <img src={Home} alt="" /> Home
              </p>
              <p
                onClick={() => {
                  props.handleClick("our-services-container");
                }}
              >
                {" "}
                <img src={Care} alt="" /> Our Services
              </p>
              <p>
                {" "}
                <img src={MailIcon} alt="" />{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="mailto: info@s2loop.com"
                >
                  {" "}
                  info@s2loop.com
                </a>
              </p>
              <p>
                {" "}
                <img src={PhoneIcon} alt="" />{" "}
                <a href="tel:+971561335667">+1 (361) 228-5129</a>
              </p>
            </div>
            <div className="bottom-footer-right-two | bottom-footer-right-one">
              <h1>Socials</h1>
              <div className="social-img">
                <img
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/s2loop/?igshid=MzRlODBiNWFlZA%3D%3D"
                    );
                  }}
                  src={Insta}
                  alt=""
                />
                <img
                  onClick={() => {
                    window.open("https://www.facebook.com/s2loop.official/");
                  }}
                  src={Facebook}
                  alt=""
                />
                <img
                  onClick={() => {
                    window.open(
                      "https://www.linkedin.com/company/s2loop/about/"
                    );
                  }}
                  src={Linkedin}
                  alt=""
                />
                 <img
                  onClick={() => {
                    window.open(
                      "https://twitter.com/s2loop1"
                    );
                  }}
                  src={Twitter}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>Copyright © 2023 S2loop Inc. CA, All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
