import React, {  useRef } from "react";
import emailjs from "@emailjs/browser";
import WhiteLogo from "../assets/WhiteLogo.png";

import Box from "@mui/material/Box"; 
import Modal from "@mui/material/Modal";
import { successToast } from "../utils/toast";
 
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  borderRadius: 2,
  width:
    window.innerWidth > 1400
      ? "1300px"
      : window.innerWidth > 900
      ? "1000px"
      : "90%",
  p: 1.3,
};
const RegisterModal2 = ({ open, handleClose }) => {  

  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      await emailjs
        .sendForm(
          `${process.env.REACT_APP_SERVICE_ID}`,
          `${process.env.REACT_APP_TEMPLATE_ID_2}`,
          form.current,
          `${process.env.REACT_APP_KEY}`
        )
        .then(
          (result) => {
            // console.log(result.text);
            form.current.reset();
            successToast("Thank you for registering with us.");
          },
          (error) => {
            // console.log(error.text);
          }
        );
    } catch (error) {
      // console.error(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="register-modal">
          <div onClick={handleClose} className="cross-image">
            <svg
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.75L20 20.75M20 1.75L1 20.75"
                stroke="black"
                stroke-width="2.23529"
              />
            </svg>
          </div>
          <div className="register-modal-left">
            <div className="modal-logo">
              <img src={WhiteLogo} alt="" />
            </div>
            <div className="modal-text">
              <h1 className="modal-text-small"> Subscribe to learn more about the latest developments in circular economy and key insights that will help your business transition towards more sustainable activities.</h1>
              {/* <p>
                Register your interest now and we'll get back to you to schedule
                a free preliminary assessment.
              </p> */}
            </div>
          </div>
          <div className="register-modal-right">
            <h1>Learn More</h1>
            <form ref={form} onSubmit={sendEmail} action="">
              <div className="register-inputs">
                <div className="register-input">
                  <p>Name</p>
                  <input
                    type="name"
                    name="name"
                    required
                    placeholder="your name"
                  />
                </div>
                <div className="register-input">
                  <p>Email</p>
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="yourmail@gmail.com"
                  />
                </div>
                <div className="register-input">
                  <p>Address</p>
                  <input
                    name="address"
                    type="text"
                    placeholder="your address"
                  />
                </div>
              </div>
              <div className="register-btn">
                <button type="submit">Register</button>
              </div>
            </form>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default RegisterModal2;
