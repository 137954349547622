import Goal2Header from "../assets/goal2bg.png";
import Goal2 from "../assets/goal2.png";
export const industrialinnovationandinfrastructure = {
  headerImg: Goal2Header,
  goalImg: Goal2,
  title: "INDUSTRY, INNOVATION AND INFRASTRUCTURE: WHY IT MATTERS",
  tagline:
    "Just <span style=color:#FD6925 > 54%</span> of the global population use the Internet. In the least developed countries only <span style=color:#FD6925 > 19%</span> have online access",
  color: "#FD6925",
  items: [
    {
      question: "What’s the goal here?",
      answer:
        "To build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation",
    },
    {
      question: "Why?",
      answer:
        "Economic growth, social development and climate action are heavily dependent on investments in infrastructure, sustainable industrial development and technological progress. In the face of a rapidly changing global economic landscape and increasing inequalities, sustained growth must include industrialization that first of all, makes opportunities accessible to all people, and second, is supported by innovation and resilient infrastructure.",
    },
    {
      question: "So what’s the problem??",
      answer:
        "Even before the outbreak of the COVID-19 pandemic global manufacturing – considered an engine of overall economic growth – has been steadily declining due to tariffs and trade tensions. The manufacturing decline caused by the pandemic has further caused serious impacts on the global economy. In addition, the airport industry, also an important driver of economic development, faced the steepest decline in its history in the first five months of 2020, with a 51 per cent drop in airline passengers due to the global lockdowns. Basic infrastructure like roads, information and communication technologies, sanitation, electrical power and water remains scarce in many developing countries. In 2019, some 87 per cent of people in developed countries used the Internet, compared with just 19 per cent in the least developed countries.",
    },
    {
      question: "How much progress have we made?",
      answer:
        "Investment in research and development globally – as well as financing for economic infrastructure in developing countries – has increased, and impressive progress has been made in mobile connectivity with almost the entire world population (97 per cent) living within reach of a mobile cellular signal.",
    },
    {
      question: "Why should I care?",
      answer:
        "Inclusive and sustainable industrialization, together with innovation and infrastructure, can unleash dynamic and competitive economic forces that generate employment and income. They play a key role in introducing and promoting new technologies, facilitating international trade and enabling the efficient use of resources. The growth of new industries means improvement in the standard of living for many of us. If industries pursue sustainability, this approach will have a positive effect on the environment.",
    },
    {
      question: "What is the price of inaction?",
      answer:
        "The price is steep. Ending poverty would be more difficult, given the industry’s role as a core driver of the global development agenda to eradicate poverty and advance sustainable development. Additionally, failing to improve infrastructure and promote technological innovation could translate into poor health care, inadequate sanitation and limited access to education.",
    },
    {
      question: "How can we help?",
      answer:
        "Establish standards and promote regulations that ensure company projects and initiatives are sustainably managed. Collaborate with NGOs and the public sector to help promote sustainable growth within developing countries. Think about how industry impacts on your life and well-being and use social media to push for policymakers to prioritize the SDGs. To find out more about Goal #9 and other Sustainable Development Goals, visit: http://www.un.org/ sustainabledevelopment",
    },
  ],
};
